// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("toc")

// jquery
import $ from 'jquery';

global.$ = $
global.jQuery = $

import "core-js/stable";
import "regenerator-runtime/runtime";

import proj4 from 'proj4';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//import Highcharts from 'highcharts'
import HighchartsData from 'highcharts/modules/data';
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts from 'highcharts/highmaps';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsMarkerClusters from 'highcharts/modules/marker-clusters';

HighchartsMarkerClusters(Highcharts);

window.Highcharts = Highcharts;
window.proj4 = window.proj4 || proj4;

//window.Highmaps = Highmaps;
